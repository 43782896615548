import * as React from "react"
import { Link, graphql } from 'gatsby'

import Layout from "../components/Layout"
import GuideRoll from "../components/cards/GuideRoll"
import ContentPageHeader from "../components/ContentPageHeader"

const GuidesIndexPage = ({ data, pageContext, location }) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout location={location}>
      <ContentPageHeader title="Guides" />
      <section className="section">
        <div className="container">
          <div className="content">
            <GuideRoll data={posts} />
          </div>
          <section className="section has-text-centered">
            <div className="columns">
              <div className="column px5">
                { pageContext.previousPagePath !== '' && 
                  <Link className="button pagination-button" to={pageContext.previousPagePath}>
                    Previous
                  </Link>
                }
              </div>
              <div className="column px5">
              { pageContext.nextPagePath !== '' && 
                <Link className="button pagination-button" to={pageContext.nextPagePath}>
                  Next
                </Link>
              }
              </div>
            </div>
          </section>
        </div>
      </section>
    </Layout>
  );
}

export const GuidesQuery = graphql`
  query GuidesQuery($skip: Int!, $limit: Int!){
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}
      filter: {frontmatter: {templateKey: {eq: "guide-post"}}}
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            description
            tags
            featuredimage {
              childImageSharp {
                gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`

export default GuidesIndexPage;